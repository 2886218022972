<template>
  <div>
    <select-shop :loadingButton="loadingButton" v-on:getData="getCurrentBill"></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 class="col-md-10 col-sm-9  col-9 font-weight-normal">
              {{ $t('report.currentbill') }}
            </h2>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <div style="overflow-x: auto">
                <DataTable :items="items" :fields="fields" :loading="loading" hover border clickableRows
                  style="white-space: nowrap;" @row-clicked="toggleDetails">
                  <template #print="{ item }">
                    <td class="py-0 text-center">
                      <CButton
                        color="primary"
                        @click="handleExportCurrentBill(item.objectId)"
                        variant="outline"
                        square
                        size="sm"
                        class="p-0 text-center"
                      >
                        <img src="/img/icon/print.png" style="width: 18px; height:18px"/>
                      </CButton>
                    </td>
                  </template>
                  <template #details="{ item, index }" @click="paymentDetail(item.paymentObjectId)">
                    <CCollapse :show="details.includes(item)">
                      <CCardBody>
                        <h5 class="font-weight-normal text-dark">
                          {{ $t('items') }}
                        </h5>
                        <br />
                        <div v-show="item.receiptStatus === $t('order')">
                          <table class="table table-borderless h5" style="width: 100%">
                            <td class="font-weight-normal" style="width: 150px">
                              {{ $t('orderNumber') }}
                            </td>
                            <td class="text-success font-weight-normal">
                              {{ item.orderNumber }}
                            </td>
                            <td v-if="item.status === $t('newOrder')" class="text-right text-success">
                              {{ item.status }}
                            </td>
                            <td v-else class="text-right text-danger">
                              {{ item.status }}
                            </td>
                          </table>
                          <table class="table table-borderless p" style="width: 100%">
                            <tbody>
                              <tr style="background-color: rgb(0, 0, 0, 0)">
                                <td class="font-weight-normal" style="width: 150px">
                                  {{ $t('timeOfOrder') }}
                                </td>
                                <td>
                                  {{ item.orderedAt }}
                                </td>
                              </tr>
                              <tr v-if="
                                item.status != $t('newOrder') &&
                                item.status != $t('reject')
                              ">
                                <td class="font-weight-normal" style="width: 150px">
                                  {{ $t('inProgressTime') }}
                                </td>
                                <td>
                                  {{ item.acceptedAt }}
                                </td>
                              </tr>
                              <tr v-if="
                                item.status != $t('newOrder') &&
                                item.status != $t('reject')
                              " style="background-color: rgb(0, 0, 0, 0)">
                                <td class="font-weight-normal" style="width: 150px">
                                  {{ $t('delivered') }}
                                </td>
                                <td>
                                  {{ item.dueDate }}
                                </td>
                              </tr>
                              <tr v-if="item.status === $t('reject')">
                                <td class="font-weight-normal" style="width: 150px">
                                  {{ $t('rejectTime') }}
                                </td>
                                <td>
                                  {{ item.rejectedAt }}
                                </td>
                              </tr>
                              <tr v-if="item.status === $t('cancel')">
                                <td class="font-weight-normal" style="width: 150px">
                                  {{ $t('cancelTime') }}
                                </td>
                                <td>
                                  {{ item.canceledAt }}
                                </td>
                              </tr>
                              <tr v-if="
                                item.status === $t('cancel') ||
                                item.status === $t('reject')
                              " style="background-color: rgb(0, 0, 0, 0)">
                                <td class="font-weight-normal" style="width: 150px">
                                  {{ $t('cause') }}
                                </td>
                                <td>
                                  {{ item.reason }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <table class="table table-striped" :class="item._classes">
                          <th v-for="field in fieldsDetail" :key="field.objectId" :class="field._classes"
                            class="text-dark">
                            {{ field.label }}
                          </th>
                          <tr style="vertical-align:top" v-for="item in itemsDetail(index)" :class="item._classes"
                            :key="item.objectId">
                            <td style="vertical-align:top">
                              {{ item.pluCode }}
                            </td>
                            <td style="vertical-align:top">
                              {{ item.pluName }}
                              <br />
                              <div v-for="option in item.orderedOptions" :key="option.objectId">
                                <small class="text-muted">
                                  <li>
                                    {{ option.optionItemName }}
                                  </li>
                                </small>
                              </div>
                            </td>
                            <td style="vertical-align:top" class="text-center">
                              {{ item.unit }} /
                              {{ item.ratio }}
                            </td>
                            <td style="vertical-align:top" class="text-center">
                              {{ item.quantitySKU }}
                            </td>
                            <td style="vertical-align:top" class="text-right">
                              {{ item.price }}
                            </td>
                            <td style="vertical-align:top" class="text-right">
                              {{ item.totalAmount }}
                            </td>
                            <td style="vertical-align:top" class="text-right">
                              {{ item.discountTotal }}
                            </td>
                            <td style="vertical-align:top" class="text-right">
                              {{ item.promotionDiscountTotal }}
                            </td>
                            <td style="vertical-align:top" class="text-right">
                              {{ item.netAmount }}
                            </td>
                          </tr>
                        </table>

                        <div v-if="paymentItems.length > 0">
                          <p v-show="item.receiptStatus === $t('Deposit')" class="font-weight-bold">
                            {{ $t('payment') }}
                          </p>
                          <table v-show="item.receiptStatus === $t('Deposit')" class="table table-borderless">
                            <tr v-for="(payment, index) in paymentdata.detail" :key="index">
                              <th class="text-right text-dark" style="width:75%">
                                {{ payment.paymentType }}
                              </th>
                              <td class="text-right text-dark" width="30%">
                                {{ payment.inputAmount }}
                              </td>
                            </tr>
                            <tr>
                              <th class="text-right text-dark">{{ $t('change') }}</th>
                              <td class="text-right text-dark">
                                {{ paymentdata.change }}
                              </td>
                            </tr>
                          </table>

                          <table v-if="item.attach.length > 0" class="table table-borderless h5" style="width: 100%">
                            <td style="width: 190px; font-size: smaller" class="font-weight-normal">
                              <p>
                                {{ $t('proofOfPayment') }}
                                <span>
                                  <CButton
                                    color="primary"
                                    v-on:click="ExportReceiptattach(item)"
                                    variant="outline"
                                    square
                                    size="sm"
                                    alt="kkkk"
                                  >
                                  <img src="/img/icon/print.png" style="width: 18px; height:18px"/>
                                  </CButton>
                                </span>
                              </p>
                            </td>
                            <td class="text-success font-weight-normal">
                              <div class="row " v-if="item.attach.length > 0">
                                <div v-for="(item, index) in item.attach" :key="index" class="col-1">
                                  <div style="max-width: 9rem;" @click="ShowImg(item)">
                                    <CImg class="card-img" :src="item.baseUrl + item.url" height="80"
                                      style="border-radius: 5px" />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </table>
                        </div>
                      </CCardBody>
                    </CCollapse>
                  </template>
                </DataTable>
              </div>
            </CCol>
          </CRow>
          <br />
          <pagination :meta_data="meta_data" v-on:next="getCurrentBill">
          </pagination>
        </CCardBody>
      </CCard>
      <CModal color="success" :show.sync="showImageModal" centered :title="$t('proofOfPayment')">
        <CImg class="card-img" :src="imagepath" width="50px" style="border-radius: 5px" />
        <template #footer>
          <div class="mx-auto"></div>
        </template>
      </CModal>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
    DataTable,
  },
  data() {
    return {
      data: [],
      paymentdata: [],
      paymentItems: [],
      details: [],
      orderDetail: {},
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      showImageModal: false,
      imagepath: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('currentBill', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'dateTime', label: this.$i18n.t('date'), _classes: 'text-left', _classes: 'text-dark font-weight-normal', },
        {
          key: 'updatedAt',
          label: this.$i18n.t('lastUpdate'),
          _classes: 'text-dark font-weight-normal',
        },
        { key: 'orderNo', label: this.$i18n.t('orderNo'), _classes: 'text-dark font-weight-normal', },
        {
          key: 'netAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'discount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'promotionDiscount',
          label: this.$i18n.t('promotionDiscount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'grandTotal',
          label: this.$i18n.t('grandTotal'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'netTotalIncVAT',
          label: this.$i18n.t('netTotalIncVAT'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'netTotalNonVAT',
          label: this.$i18n.t('netTotalNonVAT'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'netAmountVATTotal',
          label: this.$i18n.t('netAmountVATTotal'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'vatAmount',
          label: this.$i18n.t('vatAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'member',
          label: this.$i18n.t('member.txtmember'),
          _classes: 'text-dark font-weight-normal',
        },
        {
          key: 'remark',
          label: this.$i18n.t('remark'),
          _classes: 'text-dark font-weight-normal',
        },
        {
          key: 'receiptStatus',
          label: this.$i18n.t('receiptStatus'),
          _classes: 'text-left text-dark font-weight-normal',
        },
        { key: 'print', label: '', _classes: 'text-center' },
      ]
    },
    items() {
      let data = this.data
      let info = []
      for (let i = 0; i < data.length; i++) {
        const receiptItem = data[i]
        let receiptStatus = ''
        let orderNo = ''
        let paymentObjectId = '-'
        let member = '-'
        let remark = '-'

        let orderedAt = ''
        let acceptedAt = ''
        let dueDate = ''
        let shippedAt = ''
        let status = ''
        let attach = []

        let grandTotal = 0
        let netTotalIncVAT = 0
        let netAmountVATTotal = 0
        let netTotalNonVAT = 0
        let vatAmount = 0
        let serviceCharge = 0

        // summary discount
        let discount = 0
        let discountAmount = 0
        let discountItem = 0
        if (receiptItem.discountAmount != null) {
          discountAmount = receiptItem.discountAmount
        }
        if (receiptItem.discountItem != null) {
          discountItem = receiptItem.discountItem
        }
        const sumdiscount = discountAmount + discountItem
        discount = util.convertCurrency(sumdiscount)

        if (data[i].netAmount !== null) {
          data[i].netAmount = util.convertCurrency(data[i].netAmount)
        }

        if (data[i].grandTotal !== null) {
          grandTotal = util.convertCurrency(data[i].grandTotal)
        }

        if (data[i].netAmountVATTotal !== null) {
          netAmountVATTotal = util.convertCurrency(data[i].netAmountVATTotal)
        }

        if (data[i].netTotalIncVAT !== null) {
          netTotalIncVAT = util.convertCurrency(data[i].netTotalIncVAT)
        }

        if (data[i].netTotalNonVAT !== null) {
          netTotalNonVAT = util.convertCurrency(data[i].netTotalNonVAT)
        }

        if (data[i].vatAmount !== null) {
          vatAmount = util.convertCurrency(data[i].vatAmount)
        }

        if (data[i].payment != undefined) {
          paymentObjectId = data[i].payment.objectId
        } else {
          paymentObjectId = '-'
        }

        if (data[i].receiptStatus === 'W') {
          receiptStatus = this.$i18n.t('billSave')
        } else if (data[i].receiptStatus === 'O') {
          receiptStatus = this.$i18n.t('ReceivingOrder')
        } else if (data[i].receiptStatus === 'D') {
          receiptStatus = this.$i18n.t('Deposit')
        } else if (data[i].receiptStatus === 'S') {
          receiptStatus = this.$i18n.t('order')
        } else {
          receiptStatus = '-'
        }

        if (data[i].attach != undefined && data[i].attach != null) {
          attach = data[i].attach
        }

        if (data[i].member != undefined) {
          if (data[i].member.name) {
            member = data[i].member.name
          } else {
            member = '-'
          }
        }

        if (data[i].remark !== undefined && data[i].remark !== null) {
          remark = data[i].remark
        }

        if (data[i].order != undefined && data[i].order != null) {
          if (data[i].order.status === 'NEW') {
            status = this.$i18n.t('newOrder')
          } else if (data[i].order.status === 'ACCEPT') {
            status = this.$i18n.t('preparingToShip')
          } else if (data[i].order.status === 'CANCEL') {
            status = this.$i18n.t('cancel')
          } else if (data[i].order.status === 'REJECT') {
            status = this.$i18n.t('reject')
          } else {
            status = '-'
          }

          if (data[i].order.orderNumber != undefined) {
            orderNo = data[i].order.orderNumber
          }

          orderedAt = moment(data[i].order.ordered_at).format(
            'DD/MM/YYYY H:mm:ss'
          )
          acceptedAt = moment(data[i].order.accepted_at).format(
            'DD/MM/YYYY H:mm:ss'
          )
          dueDate = moment(data[i].order.dueDate).format('DD/MM/YYYY H:mm:ss')
          shippedAt = moment(data[i].order.shipped_at).format(
            'DD/MM/YYYY H:mm:ss'
          )
        } else {
          if (data[i].orderNo != undefined) {
            orderNo = data[i].orderNo
          } else {
            orderNo = data[i].id.slice(0, 10)
          }
        }

        if (data[i].serviceCharge) {
          serviceCharge = data[i].serviceCharge
        } else {
          serviceCharge = 0
        }

        info.push({
          objectId: data[i].objectId,
          paymentObjectId: paymentObjectId,
          dateTime: moment(data[i].created_at).format('DD/MM/YYYY HH:mm:ss'),
          orderNo: orderNo,
          grandTotal: grandTotal,
          receiptItems: data[i].receiptItems,
          netAmount: data[i].netAmount || '',
          netTotalIncVAT: netTotalIncVAT,
          netTotalNonVAT: netTotalNonVAT,
          netAmountVATTotal: netAmountVATTotal,
          vatAmount: vatAmount,
          receiptStatus: receiptStatus,
          member: member,
          remark: remark,
          updatedAt: moment(data[i].updated_at).format('DD/MM/YYYY HH:mm:ss'),
          attach: attach,
          orderNumber: orderNo,
          orderedAt: orderedAt,
          status: status,
          acceptedAt: acceptedAt,
          dueDate: dueDate,
          shippedAt: shippedAt,
          receiptObjectId: data[i].objectId,
          serviceCharge: util.convertCurrency(serviceCharge),
          discount: discount,
          promotionDiscount: data[i].promotionDiscountItem || 0 + data[i].promotionDiscountAmount || 0,
        })
      }
      return info
    },
    fieldsDetail() {
      return [
        { key: 'pluCode', label: this.$i18n.t('pluCode'), _classes: ' font-weight-normal' },
        { key: 'pluName', label: this.$i18n.t('productName'), _classes: ' font-weight-normal' },
        { key: 'unit', label: this.$i18n.t('unit'), _classes: ' font-weight-normal' },
        {
          key: 'quantitySKU',
          label: this.$i18n.t('quantity'),
          _classes: 'text-center font-weight-normal ',
        },
        {
          key: 'price',
          label: this.$i18n.t('priceUnit'),
          _classes: 'text-right font-weight-normal ',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right font-weight-normal ',
        },
        {
          key: 'discountTotal',
          label: this.$i18n.t('itemDiscount'),
          _classes: 'text-right font-weight-normal ',
        },
        {
          key: 'promotionDiscountTotal',
          label: this.$i18n.t('promotionDiscountTotal'),
          _classes: 'text-right font-weight-normal ',
        },
        {
          key: 'totalAmount',
          label: this.$i18n.t('totalAmount'),
          _classes: 'text-right font-weight-normal ',
        },
      ]
    },
    fieldsPayment() {
      return [
        {
          key: 'paymentType',
          label: this.$i18n.t('paymentType'),
          _classes: 'text-center',
        },
        {
          key: 'paid',
          label: this.$i18n.t('paid'),
          _classes: 'text-right',
        },
        {
          key: 'change',
          label: this.$i18n.t('change'),
          _classes: 'text-right',
        },
      ]
    },
  },
  created() {
    this.getCurrentBill()
  },
  methods: {
    ...util,
    ShowImg(item) {
      this.imagepath = item.baseUrl + item.url
      this.showImageModal = true
    },
    order(index) {
      let data = this.data[index].order
      let detail = {}
      let status = ''

      if (data != undefined || data != null) {
        if (data.status === 'NEW') {
          status = this.$i18n.t('newOrder')
        } else if (data.status === 'ACCEPT') {
          status = this.$i18n.t('preparingToShip')
        } else if (data.status === 'CANCEL') {
          status = this.$i18n.t('cancel')
        } else if (data.status === 'REJECT') {
          status = this.$i18n.t('reject')
        } else {
          status = '-'
        }

        detail = {
          orderNumber: data.orderNumber,
          orderedAt: moment(String(data.ordered_at)).format(
            'DD/MM/YYYY H:mm:ss'
          ),
          status: status,
          acceptedAt: moment(String(data.accepted_at)).format(
            'DD/MM/YYYY H:mm:ss'
          ),
          dueDate: moment(String(data.dueDate)).format('DD/MM/YYYY H:mm:ss'),
          canceledAt: moment(String(data.canceled_at)).format(
            'DD/MM/YYYY H:mm:ss'
          ),
          rejectedAt: moment(String(data.rejected_at)).format(
            'DD/MM/YYYY H:mm:ss'
          ),
          reason: data.reason,
        }
      } else {
        detail = {
          orderNumber: '',
          orderedAt: '',
          status: '',
          acceptedAt: '',
          dueDate: '',
          canceledAt: '',
          rejectedAt: '',
          reason: '',
        }
      }
      this.orderDetail = detail
    },
    itemsDetail(index) {
      let data = this.data[index].receiptItems || [];
      let info = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        let discountTotal = (item.discountAmount || 0) + (item.discountBillPerQty || 0)
        let promotionDiscountTotal = (item.promotionDiscountAmount || 0)
        let classes = 'text-dark'
        if (item.deleted_at != null) {
          classes = 'text-danger'
        }
        const totalAmount = (item.netAmount || 0) - discountTotal;
        const unit = item.unit || {}
        const unitName = unit.name || '-'
        const note = item.note || '-'

        info.push({
          pluCode: item.pluCode || '-',
          pluName: item.pluName || '-',
          quantitySKU: item.quantitySKU || 0,
          price: util.convertCurrency(item.price || 0),
          netAmount: util.convertCurrency(item.netAmount || 0),
          promotionDiscountTotal: util.convertCurrency(promotionDiscountTotal || 0),
          discountTotal: util.convertCurrency(item.discountAmount || 0),
          totalAmount: util.convertCurrency(item.totalAmount || 0),
          orderedOptions: item.orderedOptions || [],
          note: note,
          unit: unitName,
          ratio: item.ratio || 1,
          _classes: classes,
        })
      }
      return info
    },
    paymentDetail(objectId) {
      const uid = `${localStorage.getItem('shopsUid')}`
      const params = { objectId: objectId }
      const headers = { shopObjectId: this.shopObjectId }
      let info = []

      axios({
        url: '/collection/v1.0/Payment/' + uid,
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          if (res.data.data != null) {
            let data = res.data.data
            let paymentItems = res.data.data.paymentItems
            let paymentType = ''
            let detail = []

            this.paymentItems = paymentItems

            for (let i = 0; i < paymentItems.length; i++) {
              switch (paymentItems[i].paymentType) {
                case 0:
                  paymentType = this.$i18n.t('paymentType0')
                  break
                case 1:
                  paymentType = this.$i18n.t('paymentType1')
                  break
                case 2:
                  paymentType = this.$i18n.t('paymentType2')
                  break
                case 3:
                  paymentType = this.$i18n.t('paymentType3')
                  break
                case 4:
                  paymentType = this.$i18n.t('paymentType4')
                  break
                case 5:
                  paymentType = this.$i18n.t('paymentType5')
                  break
                case 6:
                  paymentType = this.$i18n.t('paymentType6')
                  break
                case 7:
                  paymentType = this.$i18n.t('paymentType7')
                  break
                case 8:
                  paymentType = this.$i18n.t('paymentType8')
                  break
                case 9:
                  paymentType = this.$i18n.t('paymentType9')
                  break
                case 10:
                  paymentType = this.$i18n.t('paymentType10')
                  break
                case 12:
                  paymentType = this.$i18n.t('paymentType12')
                  break
                case 14:
                  paymentType = this.$i18n.t('paymentType14')
                  break
                default:
                  paymentType = '-'
              }
              detail.push({
                paymentType: paymentType,
                inputAmount: util.convertCurrency(paymentItems[i].inputAmount),
                paid: util.convertCurrency(data.paid),
                change: util.convertCurrency(data.change),
              })
            }
            info = {
              detail: detail,
              paid: detail[0].paid,
              change: detail[0].change,
            }
          } else {
            info = []
          }
          this.paymentdata = info
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCurrentBill(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = { shopObjectId: shopObjectId }

      this.loading = true
      axios({
        url: '/receipt/v1.0/getunbill/' + uid,
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleExportCurrentBill(receiptObjectId) {
      this.exportCurrentBill(receiptObjectId);
    },
    exportCurrentBill(receiptObjectId) {
      const uid = `${localStorage.getItem('shopsUid')}`;
      const shopObjectId = this.shopObjectId;

      const params = {
        shopObjectId: shopObjectId,
        receiptObjectId: receiptObjectId,
      };
      const headers = {
        shopObjectId: shopObjectId,
      };

      report({
        method: 'get',
        url: '/print/v1.0/' + uid + '/unbill/a4',
        params: params,
        headers: headers,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          if (isSafari) {
            const link = document.createElement('a');
            link.href = url;
            link.download = 'ใบเสร็จ.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            const newTab = window.open(url, '_blank');
            if (newTab) {
              newTab.focus();
            }
          }
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.log(error);
        })
    },
    ExportReceiptattach(item) {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const params = {
        shopObjectId: shopObjectId,
        receiptObjectId: item.receiptObjectId,
        timezone: 'Asia/Bangkok',
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/print/v1.0/' + uid + '/receiptattach/a4',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'หลักฐานการชำระเงิน.pdf')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    toggleDetails(item, index) {
      const position = this.details.indexOf(item)
      this.order(index)
      this.paymentDetail(item.paymentObjectId)
      position !== -1
        ? this.details.splice(position, 1)
        : this.details.push(item)
    },
  },
}
</script>
